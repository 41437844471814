<template>
<div 
	@click='onClick'
	class='blockButton closeButton'
>
	<svg class='smallIcon'>
		<use xlink:href='#cross' />
	</svg>
</div>
</template>

<script>
export default {
	name: 'CloseButton',
	methods: {
		onClick(e) {
			this.$emit('click', e)
		}
	}
}
</script>

<style lang='scss'>
.closeButton {
	color: $color-white;	
	svg {
		border-radius: 8px;
		background:  $color-neutral-dark;
		transition: background .2s linear;
	}

	&.ripple svg {
		animation: closeRipple 0.8s;
	}		
}

.desktop .closeButton:hover svg{
	background: $color-alert !important;
}

@keyframes closeRipple {
  0% {
	background: $color-alert;
  }
  to {
	background: $color-neutral-shadow;
  }
}
</style>
