import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
//		fetchFolder: '/${id}',
//		fetchFolders: '/',
		fetchEducationFolders: '/education',
		fetchExerciseFolders: '/exercises',
		fetchOutcomeFolders: '/outcomes',
		fetchTemplateFolders: '/templates', // '/sets',
	},
	post: {
		createFolder: '/'
	},
	put: {
		updateFolder: '/${id}',
		moveInto: '/${id}/into/${targetId}',
		moveToLeftOf: '/${id}/toLeftOf/${targetId}',
		moveToRightOf: '/${id}/toRightOf/${targetId}'
	},
	delete: {
		destroyFolder: '/${id}'
	}
}

export const foldersApi = buildApi('folders', routes, '/clinician/folders')

