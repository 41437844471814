import { eventBus } from '@/utilities/eventBus'

const wm1 = new WeakMap()
const wm2 = new WeakMap()
const wm3 = new WeakMap()
const wm4 = new WeakMap()

const onEnter = el => {
	const timer = setTimeout(() => el.dispatchEvent(new CustomEvent('dragOverSelect')), 800)
	wm4.set(el, timer)
	el.classList.add('selectTimer')
}

const onLeave = el => {
	const timer = wm4.get(el)
	if (timer) {
		clearTimeout(timer)
		wm4.delete(el)
	}
	el.classList.remove('selectTimer')
}

const onBussing = (elFromPt, el) => {
	const contains = el.contains(elFromPt)
	if (contains && !wm3.get(el)) {
		wm3.set(el, true)
		onEnter(el)
	} else if (!contains && wm3.get(el)) {
		wm3.delete(el)
		onLeave(el)
	}
}

const onUnloadBus = el => {
	if (el.closest('.selectTimer')) el.closest('.selectTimer').dispatchEvent(new CustomEvent('dropOn'))
	onLeave(el)
}

const bind = el => {
	unbind(el)
	const fn1 = elFromPt => onBussing(elFromPt, el)
	const fn2 = () => onUnloadBus(el)
	if (!wm1.has(el)) wm1.set(el, fn1)
	if (!wm2.has(el)) wm2.set(el, fn2)
	eventBus.$on('bussing', fn1)
	eventBus.$on('unloadBus', fn2)
}

const unbind = el => {
	el.classList.remove('selectTimer')
	const fn1 = wm1.get(el)
	if (fn1) {
		eventBus.$off('bussing', fn1)
		wm1.delete(el)
	}
	const fn2 = wm2.get(el)
	if (fn2) {
		eventBus.$off('unloadBus', fn2)
		wm2.delete(el)
	}
}

export const selectOnDragOver = {
	update: function(el, binding) {
		if (binding.value) bind(el)
		else unbind(el)
	},
	unbind: function(el) {
		unbind(el)
	}
}

/*
let active

const onMouseEnter = e => {
	e.target.timer = setTimeout(() => {
		e.target.timer = null
		if (active) {
			e.target.dispatchEvent(new CustomEvent('dragOverSelect'))
		}
	}, 800)
	e.target.classList.add('selectTimer')
}

const stop = e => {
	clearTimeout(e.target.timer)
	e.target.timer = null
	e.target.classList.remove('selectTimer')
}

const onMouseLeave = stop

const onMouseUp = e => {
	active = false
	if (e.target.closest('.selectTimer')) e.target.closest('.selectTimer').dispatchEvent(new CustomEvent('dropOn'))
	stop(e)
}

const bind = el => {
	unbind(el)
	el.addEventListener('pointerenter', onMouseEnter)
	el.addEventListener('pointerleave', onMouseLeave)
	el.addEventListener('pointerup', onMouseUp)
	active = true
}

const unbind = el => {
	el.classList.remove('selectTimer')
	el.removeEventListener('pointerenter', onMouseEnter)
	el.removeEventListener('pointerleave', onMouseLeave)
	el.removeEventListener('pointerup', onMouseUp)
}

export const selectOnDragOver = {
	update: function(el, binding) {
		if (binding.value) bind(el)
		else unbind(el)
	},
	unbind: function(el) {
		unbind(el)
	}
}
*/