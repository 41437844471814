<template>
<div class="dynamicTree" @mouseenter='$emit("mouseenter")' @mouseleave='$emit("mouseleave")'>
	<div 
		class='root iconTextButton'
		:class='{ selected: selected === data.id, canDropOnFolder: $store.state.bus.bussing && editable }' 
		@click='$emit("select", selected === data.id ? null : data.id)'
		@mouseup='onMouseUp(data.id)'
	>
		<svg class='miniIcon'>
			<use :xlink:href='`#${icon}`' />
		</svg>
		<div class='label'>
			<div>{{rootTitle}}</div>
			<div>{{data.title}}</div>
		</div>
		<div 
			v-if='editable'
			class='miniButton primary'
			@click='e=>{selected === data.id ? e.stopPropagation() : null; add()}'
		>
			＋ {{ $t('elements.buttons.folder') }}
		</div>	
		<svg v-if='!editable' class='readOnly miniIcon typeIcon'>
			<use xlink:href='#lock' />
		</svg>				
	</div>
	<Tree 
		ref='tree'
		v-if="data && data.children"
		:value='data.children' 
		:indent='21'
		:draggable='editable && !$store.state.main.mobile && !isSafari'
		:ondragstart='onDragStart'
		:ondragend='onDragEnd'
		:foldAllAfterMounted='true'
		:unfoldWhenDragover='false'
		triggerClass='sortTree'
		class='tree'
	>
		<div slot-scope='{node, path, tree}'>
			<div class='verticalBar' :style="`left: ${path.length * 21}px`" />
			<div class='node' :class='{ selected: selected === node.id }'>
				<div 
					@click='node.$folded = !node.$folded'
					class='switch' 
					:class='{ open: !node.$folded }'
				>
					<svg>
						<use xlink:href='#triangle' />
					</svg>					
				</div>				
				<div 
					class='title' 
					:class='{ canDropOnFolder: $store.state.bus.bussing && editable }'
					v-selectOnDragOver='node.$folded && editable && resetDragOver'
					@click='select(node, path, tree)'
					@dragOverSelect='onDragOverSelect(node)'	
					@mouseup='onMouseUp(node.id)'
				>
					<span :class='{ sortTree: editable && selected === node.id && !$store.state.main.mobile }'>
						<span>{{node.title}}</span>
						<!--
						<svg v-if='editable && selected === node.id && !$store.state.main.mobile' class='folderMenu smallIcon' @click='rename(node)'>
							<use xlink:href='#menu' />
						</svg>
					-->
					</span>
					<div v-if='editable && selected === node.id && !$store.state.main.mobile' class='ctrls' @click.stop>
						<!--
						<div class='miniButton neutral' @click='add(node)'> 
							{{ $t('elements.buttons.addsub') }}
						</div>
						-->

						<svg class='menuIcon smallIcon'>
							<use xlink:href='#menu' />
						</svg>

						<div class='folderMenu'>
							<div class='miniButton' @click='rename(node)'> 
								{{ $t('elements.buttons.editFolder') }}
							</div>
							<div class='miniButton' @click='add(node)'> 
								{{ $t('elements.buttons.addsub') }}
							</div>							
							<div class='miniButton alert' @click='destroy(node, path, tree)'> 
								{{ $t('elements.buttons.delFolder') }}
							</div>
						</div>
						<!--
						<div class='miniClose' @click='destroy(node, path, tree)'>
							<svg class='smallIcon'>
								<use xlink:href='#cross' />
							</svg>
						</div>	
						-->					
					</div>
				</div>
			</div>
		</div>
	</Tree>
	<transition name='fade'><Loading v-show='working' /></transition>	
</div>
</template>

<script>
import { isEqual } from 'lodash'
import { Tree, Fold, Draggable, walkTreeData } from 'he-tree-vue'
import { foldersApi } from '@/services/api/modules/clinician/folders'
import { selectOnDragOver } from '@/components/common/directives/selectOnDragOver'
import { smallModalMixin } from '@/components/common/mixins/modal'
import TextInputModal from '@/components/common/modals/TextInput'
import Loading from '@/components/common/Loading'

export default {
	name: 'DynamicTree',
	components: {
		Tree: Tree.mixPlugins([Fold, Draggable]), Loading
	},
	mixins: [ smallModalMixin ],
	directives: { selectOnDragOver },	
	props: ['data','icon','rootTitle','selected','editable'],
	data() { return {
		working: false,
		resetDragOver: true,
		// until we can upgrade to vue 3 and update this component....
		isSafari: /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification))
	}},
	methods: {
		onDragStart() {},
		onDragEnd(tree, str) {
			const getSubtreeDepth = (node) => {
				let depth = 0
				if (node.children) {
					node.children.forEach(d => {
						let tmpDepth = getSubtreeDepth(d)
						if (tmpDepth > depth) depth = tmpDepth
					})
				}
				return 1 + depth
			}
			if (getSubtreeDepth(tree.getNodeByPath(str.startPath)) + str.targetPath.length > 6) {
				this.$store.dispatch('flash/showAlert', 'maxFolderDepth')
				return false
			}
			if (isEqual(str.startPath, str.targetPath)) return false
			this.move(tree,str)
			return true
		},
		onDragOverSelect(node) {
			node.$folded = false
			setTimeout(() => {
				this.resetDragOver = false
				setTimeout(() => this.resetDragOver = true)
			})
		},	
		onMouseUp(folderId) {
			if (this.$store.state.bus.bussing && this.editable && folderId !== this.selected ) {
				this.$emit('drop', { folderId, data: this.$store.state.bus.bus })
			}
		},
		select(node) {
			this.$emit('select', node.id === this.selected ? null : node.id)
		},
		expandTo(id) {
			walkTreeData(this.data.children, (node, index, parent, path) => {
				if (node && node.id === id) {
					for (const { node } of this.$refs.tree.iteratePath(path)) {
						this.$refs.tree.unfold(node)
					}
				}
			})
		},
		add(node) {
			const fn = title => {
				this.working = true
				if (!node) node = this.data
				const payload = { title, parentId: node.id }
				foldersApi.touch('createFolder', payload)
					.then(([err, folder]) => {
						if (!err) {
							folder['$folded'] = true
							folder.children = []
							if (!node.children) this.$set(node, 'children', [])
							node.children.unshift(folder)
							node.$folded = false
						}
					})
					.finally(() => this.working = false)
			}
			this.showSmallModal(TextInputModal, {
				title: this.$t('views.addFolder.title'),
				message: this.$t('views.addFolder.p1'),
				placeholder: this.$t('views.addFolder.phFolderTitle'),
				button: this.$t('elements.buttons.saveAs'),
				maxlength: 50,
				fn
			})				
		},
		rename(node) {
			const fn = title => {
				this.working = true
				const payload = { title, id: node.id }
				foldersApi.touch('updateFolder', payload)
					.then(([err, folder]) => {
						if (!err) node.title = folder.title
					})
					.finally(() => this.working = false)				
			}
			this.showSmallModal(TextInputModal, {
				title: this.$t('views.editFolderTitle.title'),
				placeholder: this.$t('views.editFolderTitle.phFolderTitle'),
				value: node.title,
				button: this.$t('elements.buttons.saveAs'),
				maxlength: 50,
				fn
			})
		},
		destroy(node, path, tree) {
			this.working = true
			foldersApi.touch('destroyFolder', { id: node.id })
				.then(([err]) => {
					if (err) {
						this.$store.dispatch('flash/showAlert', 'folderNotEmpty')
					} else {
						const parent = tree.getNodeParentByPath(path)
						tree.removeNodeByPath(path)
						if (parent) {
							this.select(parent)
						} else {
							this.$emit("select", this.data.id)
						}
					}
				})
				.finally(() => this.working = false)
		},
		move(tree, str) {
			const start = str.startPath
			const target = str.targetPath			
			const orig = tree.getNodeByPath(start)
			const index = target.at(-1)
			let route, payload = { id: orig.id }
			if (index > 0) {
				const n = [...target]
				const last = n.pop()
				n.push(last - 1)
				const node = tree.getNodeByPath(n)
				route = 'moveToRightOf'
				payload.targetId = node.id
			} else if (index === 0) {
				const parent = tree.getNodeParentByPath(target)
				if (parent && (!parent.children || !parent.children.length)) {
					route = 'moveInto'
					payload.targetId = parent.id
				} else {
					route = 'moveToLeftOf'
					const node = tree.getNodeByPath(target)
					payload.targetId = node.id
				}
			}
			this.working = true
			foldersApi.touch(route, payload)
				.finally(() => this.working = false)						
		}
	},
	watch: {
		
		data() { // because the data arrives after mounted
			this.$nextTick(()=> {
				if (this.$refs.tree && this.selected && this.data.children) {
					this.expandTo(this.selected)	
				}
			})
		},
		
		selected(id) {
			if (id) this.expandTo(id)
		}
	},
	mounted() {
		if (this.$refs.tree && this.selected && this.data.children) {
			this.expandTo(this.selected)	
		}
	}
}
</script>

<style lang='scss'>
#modals-container .dynamicTree:first-child .root {
	background: none;
	border: none;
} 

.dynamicTree {
	position: relative;
		padding-bottom: $size-gutter * 2;
		margin-bottom: 14px;
		border-bottom: 1px solid $color-neutral-shadow;	
		margin-right: 14px;
/*
	&:first-child .root {
		border: 1px solid transparent;
		border-right: none;
		border-bottom: none;
		border-top-left-radius: 7px;	
	}
*/

	.root {
		padding-left: 7px;
		margin-left: 1px;
		padding-right: 0;
		padding-top: 5px;
		padding-bottom: 5px;
		height: auto;
		z-index: 10;
		justify-content: left;	
		.label { 
			margin-right: $size-gutter * 2;
			display: block;
			> div:first-child { font-size: $size-font-small; color: $color-primary-accent; font-weight: bold; margin-bottom: 1px; }
		}
		&.selected {
			background: $color-neutral;
			border-radius: 2px;
			/*
			text-decoration: underline;
			*/
		}
		.miniButton { 
			pointer-events: auto;
			border-radius: 12px;
			padding-left: $size-gutter;
			padding-right: $size-gutter * 2;
			margin-left: auto; 
			margin-right: $size-gutter;
			transform: scale(.9);
			font-size: $size-font-standard;
		}		
		&.canDropOnFolder { 
			cursor: copy; 
			&:hover { background: $color-focus !important; color: $color-black; }
		}		
		.readOnly { 
			color: $color-neutral-dark;
			white-space: nowrap;
		}		
	}

	.tree {
		font-size: $size-font-standard;
		line-height: $size-lineHeight-standard;
		padding-left: 24px;
		padding-right: $size-gutter * 2;
		margin-top: 9px;

		.switch {
			position: absolute;
			z-index: 1;
			top: 5.5px;
			left: -15.5px;
			width: 12px;
			height: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			> svg { 
				width: 100%;
				height: 100%;
				color: $color-neutral-dark;
				transform-origin: center;
				transform: rotate(-30deg) scale(0.8);

			}

			&.open { 
				border: 0;
				top: 4.5px;
				left: -12.5px;
				> svg {
					color: $button-gradient-primary;
					transform: rotate(60deg) scale(0.8);
				}
			}
		}

			.selected .switch svg { color: $color-primary-accent; }


		.title {
			position: relative;
			margin-left: 1.5px;
			border-radius: 2px;
			cursor: pointer;	
			> span {
				display: flex;
				align-items: top;
				padding-top: 4px;
				padding-bottom: 3px;
				padding-left: $size-gutter;
				padding-right: $size-gutter * 4;
				&.sortTree { cursor: move; }	
				> span { flex: 1; }
				> svg { 
					margin-left: auto;
					cursor: pointer; 
					width: 14px;
					height: 14px;
					flex-basis: 14px;
					position: relative;
					top: 2px;
					left: 1px;
				}
			}
			&.canDropOnFolder { 
				cursor: copy; 
				&:hover { background: $color-focus !important; color: $color-black; }
			}			
			&.selectTimer:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
/*				backdrop-filter: sepia(100%); */
				background: $color-neutral-panel;
				animation: selectTimer 1000ms linear
			}
		}

		.ctrls {
			width: 100%;

			.menuIcon {
				position: absolute;
				top: 4px;
				right: 5px;
				color: $color-primary-accent;
			}

			.folderMenu {
				position: absolute;
				top: 24px;
				right: 0px;
				background: $color-neutral-silver;
				border: 1px solid $color-neutral-shadow;
				box-shadow: 0 4px 6px -2px rgba(0,0,0,.08);
				border-radius: 2px;
				padding: $size-gutter;
				transition: opacity 200ms linear;
				opacity: 0;
				pointer-events: none;
				color: $color-black;
				transition-delay: 200ms;
				> div:not(:last-child) { margin-bottom: 1px; }
				> div { border-radius: 2px;  padding: 0 $size-gutter * 2; }
				&:after {
					position: absolute;
					top: -24px;
					left: 0;
					height: 25px;
					width: 100%;
					content: '';
				}
			}

			&:hover {
				.folderMenu {
					opacity: 1;
					pointer-events: auto;
				}
			}

			.miniButton {
				flex: 1;
				border-radius: 0;
			}
			.miniClose {
				position: absolute;
				top: 5px;
				right: 5px;
				height: 16px;
				border-radius: 50%;
				transition: background 200ms;
				&:hover {
					background: $color-alert !important;
					svg { color: $color-white; }
				}			
			}		
		}

		.selected {
			position: relative;
			z-index: 20;
			.title {
			/*	text-decoration: underline; */
				background: $color-neutral;
			/*	background: $color-focus !important; */
				/* color: $color-primary-accent; */
					/* color: #fff; */
			}		
		}
		.tree-node-back { padding-right: $size-gutter * 2; }
		/*
		.tree-node-back:hover {
			.title { background: $color-neutral-accent; }
		}
		*/

	}

/* hierarchy lines */

	.node { 
		position: relative; 
/*
		&.tick:before {
			position: absolute;
			top: 12px;
			left: -12px;
			height: 1px;
			width: 7px;
			background: $color-neutral-dark;			
			content: "";
		}
*/
	}
/*
	.verticalBar {
		position: absolute;
		top: 0;
		width: 1px;
		height: 100%;
		background: $color-neutral-shadow;
		margin-left: -29px;
	}

	.tree-branch {
		position: relative;
		&:last-child {
			> div > div > div > .verticalBar {
				display: none;
			}
			> div > div > div > .node:after {
				height: 12px;
			}
		}
		&:only-child {
			> * > * > .verticalBar {
				display: none;
			}
		}
	}
*/
/* placeholder */

	.tree-placeholder-node {
		height: 50px;
		background: #fff;
		margin-left: 2px;
		position: relative;
		border-radius: 2px;
	/*	border: 1px solid $color-neutral-shadow; */
	}

}
</style>

